<template>
  <div id="InformationFill">
    <div class="main-theme">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230831164200686.png" />
    </div>
    <yellowTitle style="margin-top: 20px" info="填写资料" :not-positon="true"></yellowTitle>
    <div class="fill_box">
      <div class="fill_box_title">姓名</div>
      <div class="fill_box_content_box">
        <input v-model="agentName" class="fill_box_content_box_input" type="text" placeholder="请输入姓名">
      </div>
    </div>
    <div class="fill_box">
      <div class="fill_box_title">性别</div>
      <div class="fill_box_content_box">
        <div @click="changeSex(1)" class="choose_sex">男
          <van-icon :name="sex == 1 ? 'checked' : 'circle'" color="#B9905C" />
        </div>
        <div @click="changeSex(2)" style="margin-left: 30px" class="choose_sex">女
          <van-icon :name="sex == 2 ? 'checked' : 'circle'" color="#B9905C" />
        </div>
      </div>
    </div>
    <div class="fill_box">
      <div class="fill_box_title">民族</div>
      <div @click="showMinzu = true" class="fill_box_content_box fill_box_content_box_2">
        <div class="xuanze_words">{{ minZuIndex >= 0 ? minZus[minZuIndex] : '请选择' }}</div>
        <van-icon name="arrow" color="#999" size="16" />
      </div>
    </div>
    <div class="fill_box" v-if="this.familyType === 1">
      <div class="fill_box_title">店主电话</div>
      <div class="fill_box_content_box">
        <input v-model="agentPhone" disabled class="fill_box_content_box_input" type="number" placeholder='请填写您的手机号'>
      </div>
    </div>
    <div class="fill_box" v-else>
      <div class="fill_box_title">意向店主电话</div>
      <div class="fill_box_content_box">
        <input v-model="phone" class="fill_box_content_box_input" type="number" placeholder='请填写您的手机号'>
      </div>
    </div>
    <div class="fill_box">
      <div class="fill_box_title">身份证号</div>
      <div class="fill_box_content_box">
        <input v-model="agentIdCard" class="fill_box_content_box_input" type="text" placeholder="请填写您的身份证号码">
      </div>
      <!-- @input="onIdCardChange" -->
    </div>
    <!-- <div class="fill_box" v-if="familyType === 1">
      <div class="fill_box_title">授权编码</div>
      <div class="fill_box_content_box">
        <input v-model="agentCode" disabled class="fill_box_content_box_input" type="text" placeholder="没有可不填写">
      </div>
    </div> -->
    <div @click="show = true" class="fill_box">
      <div class="fill_box_title">所在地区</div>
      <div class="fill_box_content_box">
        <input v-model="area" :disabled="familyType === 1" placeholder="请选择所在地区" class="fill_box_input" type="text">
      </div>
    </div>

    <div class="fill_box">
      <div class="fill_box_title">详细地址</div>
      <div class="fill_box_content_box">
        <input v-model="address" placeholder="如道路、门牌号、小区、单元室等" class="fill_box_content_box_input" type="text">
      </div>
    </div>
    <div class="fill_box flex-normal">
      <div class="check-box">
        <van-icon size="16" :name="attendConference === 1 ? 'checked' : 'circle'" color="#B9905C" @click="handleAttend" />
        <span class="font-normal">参加蜜蛋2025年会（{{this.familyType === 1?888:288}}元定位费）</span>
      </div>
      <div class="send-cont">
        <p>参会赠送</p>
        <p>赠送会议当天午餐和晚餐，会议酒店两晚住宿</p>
        <p>赠送蜜乐儿重组胶原蛋白冷敷贴3盒</p>
        <p v-if="this.familyType === 2">梵洁诗DNA钠水光动能液1套</p>
        <template v-if="this.familyType === 1">
          <p>赠送以下商品2选1</p>
          <div class="check-box" @click="prizeType = 1">
            <van-icon size="16" :name="prizeType === 1 ? 'checked' : 'circle'" color="#B9905C" />
            <span class="font-normal">梵洁诗DNA钠水光动能液1套</span>
          </div>
          <div class="check-box" @click="prizeType = 2">
            <van-icon size="16" :name="prizeType === 2 ? 'checked' : 'circle'" color="#B9905C" />
            <span class="font-normal">梵洁诗光蕴无瑕组合1套</span>
          </div>
        </template>
      </div>
    </div>
    <div class="fill_box flex-normal">
      <div class="f-tit">美妆截止2024年9月30日,大健康截止2025年3月31日积分可参与2025年蜜蛋年会旅游报名费用兑换；支持按1积分价值5元标准在积分抵扣后进行补差支付</div>
      <div class="check-box" @click="attendTourism = 1">
        <van-icon size="16" :name="attendTourism === 1 ? 'checked' : 'circle'" color="#B9905C" />
        <span class="font-normal">参加旅游（成人：1380元定位费）</span>
      </div>
      <div class="check-box" @click="attendTourism = 2">
        <van-icon size="16" :name="attendTourism === 2 ? 'checked' : 'circle'" color="#B9905C" />
        <span class="font-normal">参加旅游（儿童身高＜1.2m：980元定位费）</span>
      </div>
      <div class="check-box" @click="attendTourism = 3">
        <van-icon size="16" :name="attendTourism === 3 ? 'checked' : 'circle'" color="#B9905C" />
        <span class="font-normal">参加旅游（儿童身高≥1.2m：1380元定位费）</span>
      </div>
      <div class="check-box" @click="attendTourism = 0">
        <van-icon size="16" :name="attendTourism === 0 ? 'checked' : 'circle'" color="#B9905C" />
        <span class="font-normal">不参加旅游</span>
      </div>
      <div class="hint">注：请按身高实际填报，产生超高费用需补齐1380元</div>
      <div class="points">当前剩余积分 <span>{{pointsNum}}</span></div>
      <div class="preStorage">
        <input type="number" v-model="pointsAvailable" :placeholder="'输入你要抵扣的积分值'" />
        <span @click="mustMoney">最大</span>
      </div>
      <!-- <div class="points cent">当前积分可抵扣金额<span>{{pointsAmount}}元</span></div> -->

    </div>
    <!-- <div class="box box_2" v-show="!underAge">
      <yellowTitle info="选择服装"></yellowTitle>
      <div class="box_2_tips">*该服装属于定制产品，选择后无法更换，请谨慎选择</div>
      <div class="box_2_words">蜜蛋定制防晒服：</div>
      <van-radio-group class="size-list" v-model="sizeType">
        <div class="size-item" v-for="(it, index) in allSizeList" :key="index">
          <label>{{ it.title }}</label>
          <van-radio :name="text" v-for="(text, idx) in it.child" :key="idx" checked-color="#1AC1F3">
            {{ text }}
          </van-radio>
        </div>
      </van-radio-group>
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230620102025564.png" class="chima" alt="">
    </div> -->
    <div @click="changeAgree" class="agree_box">
      <van-icon size="16" :name="agree ? 'checked' : 'circle'" color="#B9905C" />
      <div class="words_agree">*我已阅读并同意
        <span @click.stop="showProtocol = true">会议免责声明</span>和
        <span @click.stop="showProtocol = true">报名安全注意事项</span>和<span @click.stop="showProtocol = true">60周岁以上旅行社免责协议书</span>，此项会 议免责声明将在现场签署，请认真阅读
      </div>
    </div>
    <div @click="submit" class="sure_btn">确认提交（需支付{{ getMettingFee }}元）</div>
    <!-- <div class="sure_words">*店主提交成功后支持家属报名申请**</div> -->
    <!-- 选择民族 -->
    <van-popup v-model="showMinzu" position="bottom">内容
      <van-picker show-toolbar :columns="minZus" @confirm="chooseMinzu" @cancel="onCancel" />
    </van-popup>
    <!-- 选择省市区 -->
    <van-popup v-model="show" round position="bottom">
      <van-cascader v-model="area" title="请选择所在地区" :options="cityData" @close="closeCascader" @finish="onFinish" active-color="#004E8F" />
    </van-popup>
    <van-popup v-model="showProtocol" position="top" :style="{ height: '80%' }">
      <protocol @close="onProtoClose" />
    </van-popup>
    <SelectPayType :showPayLayer="showPayLayer" :payAmount="getMettingFee" @close="onAppPayCancel()" @pay="onAppPay" :isApp="isInApp" />
  </div>
</template>

<script>
import yellowTitle from "./components/yellowTitle";
import protocol from "./components/protocol.vue"
import { cityData4 } from "../../../utils/city.data-4";
import { Subtr, accMul } from "../../../utils/handle"
import { trim, regTel } from "../../../utils/public"
import { deliveryMessage } from "../../../utils/appUtil";
import { wxPay } from "../../../utils/wxPay"
import SelectPayType from "../../../components/Order/SelectPayType";

export default {
  name: "InformationFill",
  components: { yellowTitle, protocol, SelectPayType },
  data() {
    return {
      allSizeList: [
        { id: 1, title: "成人尺码选择", child: ["L", "2XL", "4XL"] },
      ],
      sizeType: 'L',
      sex: 2,
      agree: false,
      info: '',
      agentName: '',
      agentPhone: '',
      agentIdCard: '',
      agentCode: '',
      area: '',
      address: '',
      jifen: '',
      minZus: ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族',
        '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '傈僳族', '佤族', '畲族',
        '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族',
        '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族',
        '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族',
        '基诺族'],
      minZuIndex: -1,
      showMinzu: false,
      familyType: 1,
      phone: '',
      cityData: cityData4,
      show: false,
      showProtocol: false,
      lastNum: 0,
      codeId: "",
      validCode: "",
      endTime: 60,
      isInApp: '0',
      underAge: true, //是否是未成年
      attendConference: 1, //参加发布会 0不参加 1参加 
      attendTourism: -1,//参加旅游   //参加旅游的类型 0不参加 1成人  2儿童身高<1.2米的 3儿童身高>=1.2m的
      isNeedBed: 1,//是否需要占床  0不需要 1需要
      payType: 0,
      showPayLayer: false,
      orderId: "",
      pointsNum: 0,
      pointsAvailable: '',
      payMayObj: null,
      prizeType: 0
    }
  },
  created() {
    // this.countDown();
    let { inApp, type, replace } = this.$route.query;
    if (inApp === '1') this.isInApp = inApp;
    let _href = window.location.href;
    if (replace !== '1') {
      window.location.replace(_href + `&replace=1`)
    }
    this.familyType = +type;
    this.attendConference = +type === 1 ? 1 : 0
    this.Init()
    // this.getWXShow()
  },

  methods: {
    getWXShow() {
      this.get('/Base/Pay/GetPayWayIsOpen', "", 2).then(res => {
        if (res.code === 1) {
          let obj = res.response;
          this.payMayObj = obj;
        }
      })
    },
    handleAttend() {
      // if (this.familyType === 1) return
      this.attendConference = this.attendConference ? 0 : 1;
    },
    mustMoney: function () {//最高金额
      var mustMoney = this.pointsNum
      this.pointsAvailable = mustMoney
    },
    Init() {
      this.post('/OnlyOnce/AnnualMeeting/GetAgentDetails2025', { source: 1 }, true, 2).then(json => {
        if (json.code == 0) return this.$dialog.alert({ title: '提示', message: json.msg }).then(() => {
          this.$router.push("/")
        });
        let data = json.response;
        if (this.familyType === 2 && !data.existsApply) {
          this.$dialog.alert({ title: '提示', message: '店主本人报名成功后才支持意向店主报名噢，请联系店主报名' }).then(() => {
            this.$router.push("/signUp?inApp=" + this.isInApp)
          })
        }
        if (this.familyType === 1) {  //代理自动填写资料
          this.agentName = data.agentName;
          this.agentPhone = data.agentPhone;
          this.agentIdCard = data.agentIdCard;
          this.agentCode = data.agentCode;
          let { country, province, city, area, detailAddr } = data.addr;
          this.area = country + "/" + province + "/" + city + "/" + area;
          this.address = detailAddr;
          this.sex = data.agentSex;
        }
        this.pointsNum = data.points
        this.info = data;
      })
    },
    getAddressCode() {
      this.get("/staticSourceTemp/FixedFile/city.data-export.json", {}, 3).then(res => {
        this.cityData = res.cityData4;
      });
    },
    closeCascader() {
      this.show = false
      this.area = ''
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.area = selectedOptions.map((option) => option.text).join('/');
    },
    sendMsgCode() {
      if (this.lastNum > 0) return;
      let tel = trim(this.phone);
      if (!tel) return this.$dialog.alert({ title: "提示", message: '手机号码不能为空!' });
      if (!regTel(tel)) return this.$dialog.alert({
        title: "提示",
        message: '手机号格式不正确!'
      });
      this.$toast.loading({ message: '正在发送...', duration: 0, mask: true });
      this.post("/CommAPI/Comm/SendPhoneCode_V2", {
        phone: tel,
        type: 1,
        nationCode: "86"
      }, true, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          this.lastNum = 60;
          this.codeId = json.response.codeID
          let storage = window.localStorage;
          let obj = JSON.parse(storage.getItem(tel)) || {};
          let count = obj.count || 0;
          obj.count = count + 1;
          storage.setItem(tel, JSON.stringify(obj));
          let keyword = "signLastTime";
          window.localStorage[keyword] = (+new Date());
          this.countDown();
          this.$dialog.alert({ title: "提示", message: '验证码已发送至手机,请注意查收!' });
        } else {
          this.$dialog.alert({ title: "提示", message: json.msg });
        }
      });
    },
    //验证码获取倒计时
    countDown: function () {
      let last_time = window.localStorage['signLastTime'];
      let time_distance = (+new Date()) - last_time;
      /* 时间差：活动结束时间减去当前时间  */
      let int_second = Math.floor(time_distance / 1000);
      let downTime = this.endTime - int_second;
      if (downTime > 0) {
        setTimeout(() => {
          this.countDown();
        }, 1000);
        this.lastNum = downTime;
      } else {
        this.lastNum = 0;
        window.localStorage.removeItem("signLastTime");
      }
    },
    submit() {
      if (!this.agentName) {
        return this.$dialog.alert({ title: '提示', message: '请填写姓名' })
      }
      if (this.minZuIndex < 0) {
        return this.$dialog.alert({ title: '提示', message: '请选择民族' })
      }
      if (this.familyType === 2 && !this.phone) {
        return this.$dialog.alert({ title: '提示', message: '请填写正确的电话号码' })
      }
      if (!this.agentIdCard) {
        return this.$dialog.alert({ title: '提示', message: '请填写正确的身份证号' })
      }
      let pattern = /\d{17}[\d|x]|\d{15}/;
      if (!pattern.test(this.agentIdCard)) {
        return this.$dialog.alert({ title: '提示', message: '身份证号码格式不正确！' })
      }
      if (this.area == '') {
        return this.$dialog.alert({ title: '提示', message: '请选择地区' })
      }
      if (this.address == '') {
        return this.$dialog.alert({ title: '提示', message: '请填写地址' })
      }
      if (this.attendTourism < 0) {
        return this.$dialog.alert({ title: '提示', message: '请选择是否旅游' })
      }
      if (!this.prizeType && this.familyType === 1 && this.attendConference) return this.$dialog.alert({ title: '提示', message: '请选择赠送商品2选1' })
      // if (this.familyType === 1 && !this.sizeType) return this.$dialog.alert({ title: '提示', message: '请选择服装' });
      if (!this.agree) {
        return this.$dialog.alert({ title: '提示', message: '请阅读并同意旅游协议' })
      }
      this.sureSubmit();
    },
    sureSubmit() {
      // if (this.orderId && this.isInApp !== '1') {
      //   this.getPayInfo(this.orderId);
      //   return;
      // }
      if (!this.attendConference && !this.attendTourism) return this.$dialog.alert({ title: '提示', message: '报名费用不支持0元支付，请选择并支付您要参加的内容' })
      this.$toast.loading({ message: '请稍后', duration: 0 })
      this.post('/OnlyOnce/AnnualMeeting/SignUp2025', {
        // source: 1,
        signupType: this.familyType,
        name: this.agentName,
        minZu: this.minZus[this.minZuIndex],
        sex: this.sex,
        phone: this.familyType === 1 ? this.agentPhone : this.phone,
        idCard: this.agentIdCard,
        // clothStr: this.underAge ? '' : this.sizeType,
        addrStr: this.area.split('/').join('') + this.address,
        // codeId: this.codeId,
        code: this.validCode,
        isConference: this.attendConference,
        joinTravelType: this.attendTourism,
        usePoints: this.pointsAvailable,
        prizeType: this.attendConference === 1 ? (this.familyType === 2 ? 1 : this.prizeType) : 0
      }, true, 2).then(data => {
        this.$toast.clear()
        if (data.code == 1) {
          this.$toast("报名成功");
          this.$router.push('/Recharge/AnnualMeetingList?inApp=' + this.isInApp)
          // let { orderId } = data.response;
          // this.orderId = orderId;
          // if (this.isInApp === '1') {
          //   this.showPayLayer = true;
          // } else {
          //   this.getPayInfo(orderId);
          // }
        } else {
          this.$dialog.alert({
            title: '提示',
            message: data.msg
          })
        }
      }).catch(() => this.$toast.clear())
    },
    getPayInfo(orderId) {
      this.$toast.loading({ duration: 0, message: '支付中...', overlay: true });
      this.get('/OnlyOnce/AnnualMeeting/PayOrder2025', {
        orderId, payType: 1,
      }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response.wechat;
          wxPay({
            appId: data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
            paySign: data.sign, signType: data.signType, package: data.package
          }).then(() => {
            this.$toast("报名成功");
            this.$router.push('/Recharge/AnnualMeetingList?inApp=' + this.isInApp)
          }).catch(msg => {
            this.$dialog.alert({ title: '提示', message: msg }).then(() => {
              this.$router.push('/Recharge/AnnualMeetingList?inApp=' + this.isInApp)
            })
          })
        }
      }).catch(() => this.$toast.clear())
    },
    chooseMinzu(value, index) {
      this.minZuIndex = index
      this.showMinzu = !this.showMinzu
    },
    onCancel() {
      this.showMinzu = !this.showMinzu
    },
    changeSex(e) {
      if (this.familyType === 2) {
        this.sex = e
      }
    },
    changeAgree() {
      this.agree = !this.agree
    },
    onProtoClose() {
      this.agree = true;
      this.showProtocol = false;
    },
    //获取年龄
    getAgeByIdCard(IdCard) {
      let ageDate = new Date()
      let month = ageDate.getMonth() + 1
      let day = ageDate.getDate()
      let age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
      if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day) {
        age++
      }
      if (age <= 0) {
        age = 1
      }
      return age
    },
    //身份证号码输入变化
    onIdCardChange(e) {
      let pattern = /\d{17}[\d|x]|\d{15}/;
      let value = e.target.value;
      if (pattern.test(value)) {
        let age = this.getAgeByIdCard(value);
        if (age < 18) {
          this.underAge = true;
        } else if (age < 60) {
          this.$dialog.alert({ title: "提示", message: "请前往‘蜜蛋店铺’或‘蔓薇健康’小程序报名。" })
        }
      } else {
        this.underAge = false;
      }
    },
    //app 支付
    onAppPay(payType) {
      this.showPayLayer = false;
      let realPayType = payType === '1' ? 2 : 1;
      let message = { action: "jumpNewTravelPay", data: { orderId: this.orderId, payType: realPayType } };
      deliveryMessage(message);
    },
    onAppPayCancel() {
      this.showPayLayer = false;
      this.$router.push('/Recharge/AnnualMeetingList?inApp=1')
    }
  },
  computed: {
    pointsAmount() {
      let money = 0
      if (this.pointsAvailable) money = accMul(this.pointsAvailable, 5)
      return money
    },
    getMettingFee() {
      let amount = this.attendConference ? (this.familyType === 1 ? 888 : 288) : 0;
      if (!this.info) return amount;
      let travelAmount = 0
      if (this.attendTourism === 1) travelAmount = 1380
      else if (this.attendTourism === 2) travelAmount = 980
      else if (this.attendTourism === 3) travelAmount = 1380
      if (this.pointsAmount) travelAmount = Subtr(travelAmount, this.pointsAmount)
      travelAmount = +travelAmount > 0 ? travelAmount : 0
      amount = Subtr(amount, -travelAmount)
      return +amount
    }
  },
  watch: {
    pointsAvailable: function (val) {
      var mustMoney = this.pointsNum
      if (+val > +mustMoney) {
        this.pointsAvailable = mustMoney;
      }
    },
  },
  mounted() {
    window.scroll({ top: 0 });
    window.orderSuccess = () => {
      this.$router.push('/Recharge/AnnualMeetingList?inApp=1')
    }
    window.clearPrompt = () => {
      this.$router.push('/Recharge/AnnualMeetingList?inApp=1')
    }
  },
}
</script>

<style lang="scss">
.preStorage {
  padding: 15px 0 10px;
  position: relative;
  input {
    width: calc(100% - 50px);
    border: 0;
    margin-bottom: 0;
    line-height: 21px;
    padding: 6px 15px 6px 25px;
    outline: 0;
    background-color: #fff;
    font-size: 14px;
  }

  span {
    width: 62px;
    height: 33px;
    border-radius: 6px;
    display: block;
    position: absolute;
    text-align: center;
    line-height: 33px;
    color: #fff;
    right: 0;
    top: 15px;
    background: #e0bd90;
  }
}
.main-theme {
  img {
    width: 100%;
  }
}

#InformationFill {
  padding-bottom: 30px;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 0;
  background-position: 0px -3px;

  input:disabled {
    background-color: transparent;
  }

  .box {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #fff;
    padding: 25px 10px 10px 10px;
    position: relative;
  }

  .box_2 {
    font-size: 12px;
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;

    .box_2_tips {
      color: #1ac1f3;
      // text-align: start;
    }

    .box_2_words {
      text-align: start;
      color: #333;
      padding-top: 16px;
    }

    .line {
      margin-top: 3px;
      height: 1px;
      background-color: #ccc;
    }

    .chima {
      width: 100%;
      margin-top: 10px;
    }

    .size-list {
      padding-bottom: 10px;

      .size-item {
        display: flex;
        padding: 5px 0;
        align-items: center;

        label {
          color: #333;
        }

        .van-radio {
          margin-right: 10px;
          flex-direction: row-reverse;

          .van-radio__label {
            margin-right: 10px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .box_1 {
    color: #666666;
    padding-left: 20px;
    padding-right: 20px;

    .van-radio-group {
      padding-top: 10px;

      .van-radio {
        padding: 5px 0;

        .van-radio__icon {
          font-size: 19px;
          margin-right: 10px;

          &:not(.van-radio__icon--disabled) {
            .van-icon {
              border-color: #f0169a;
            }
          }
        }

        .van-radio__label {
          color: #666;
          font-size: 14px;
        }
      }
    }

    .line {
      margin-top: 22px;
      height: 1px;
      background-color: #ccc;
    }

    .deduction-list {
      .de-item {
        position: relative;
        padding-bottom: 18px;

        &:after {
          content: '';
          position: absolute;
          left: 15px;
          right: 15px;
          height: 1px;
          bottom: 0;
          background-color: #ccc;
        }

        &:last-child {
          &:after {
            height: 0;
          }
        }
      }
    }

    .words {
      margin-top: 18px;
      text-align: left;
      color: #333;
    }

    .input_box {
      display: flex;
      margin-top: 10px;

      .jifen_input {
        flex: 1;
        background-color: #fff0f9;
        border-radius: 5px 0 0 5px;
        border: none;
        font-size: 10px;
        box-sizing: border-box;
        padding-left: 15px;
      }

      .max_btn {
        width: 60px;
        height: 34px;
        background-color: #ff3cb2;
        border-radius: 0 5px 5px 0;
        text-align: center;
        line-height: 34px;
        color: #fff;
      }
    }

    .words_2 {
      color: #f0169a;
      margin-top: 10px;
      text-align: center;

      .zero_words {
        font-size: 24px;
        display: inline-block;
        margin-left: 1px;
        margin-right: 1px;
        position: relative;
        top: 1px;
      }
    }
  }

  .box_3 {
    .box_2_words {
      color: #999;
    }
  }

  .fill_box {
    margin-top: 26px;
    display: flex;
    min-height: 32px;
    border-radius: 10px;
    background-color: #f9f6f1;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    color: #333333;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid #b9905c;
    .f-tit {
      font-size: 12px;
      text-align: left;
      padding-top: 15px;
    }
    .hint {
      color: #f2a13a;
      text-align: left;
      font-size: 12px;
      padding-bottom: 10px;
    }
    .points {
      text-align: left;
      span {
        color: #b9905c;
      }
      &.cent {
        text-align: center;
        padding-bottom: 20px;
        span {
          padding-left: 10px;
        }
      }
    }
    .send-cont {
      text-align: left;
      font-size: 12px;
      padding: 10px 0;
      .check-box {
        height: 22px;
      }
      p {
        padding-bottom: 5px;
      }
    }
    .fill_box_input {
      height: 32px;
      flex: 1;
      border: none;
    }

    .fill_box_title {
      min-width: 70px;
      text-align: start;
    }

    .fill_box_content_box {
      height: 34px;
      flex: 1;
      display: flex;

      .fill_box_content_box_input {
        width: 100%;
        border: none;
        background-color: transparent;
      }

      .choose_sex {
        display: flex;
        align-items: center;
        color: #666;

        .van-icon {
          font-size: 18px;
          margin-left: 4px;
        }
      }
    }

    .fill_box_content_box_2 {
      justify-content: flex-end;
      align-items: center;

      .xuanze_words {
        color: #999;
      }

      .arrow_icon {
        width: 7px;
        height: 13px;
        margin-left: 8px;
      }
    }

    .font-normal {
      font-size: 12px;
    }

    &.flex-normal {
      display: block;
    }

    .check-box {
      display: flex;
      height: 32px;
      align-items: center;

      .font-normal {
        padding-left: 4px;
      }
    }
  }

  .notice-txt {
    color: #ff5144;
    font-size: 12px;
    margin: 16px 15px 0;
    border-radius: 4px;
    text-align: left;
    padding: 4px 10px;
    line-height: 20px;
    background-color: #fcebea;
  }

  .telphone {
    display: flex;
    padding-right: 15px;
    align-items: center;

    .fill_box {
      flex: 1;
    }

    .send-code {
      width: 107px;
      height: 34px;
      color: #209dff;
      font-size: 12px;
      margin-top: 12px;
      line-height: 34px;
      border-radius: 10px;
      background-color: #fff;
    }
  }

  .libao {
    width: 94%;
    margin-top: 15px;
  }

  .agree_box {
    display: flex;
    color: #b9905c;
    font-size: 10px;
    padding: 20px;
    margin-top: 10px;

    .circle_agree {
      width: 16px;
      height: 16px;
      position: relative;
      top: 2px;
      border-radius: 50%;
      background-color: #b9905c;
      margin-right: 8px;
      margin-left: 20px;
    }

    .words_agree {
      flex: 1;
      padding-left: 5px;
      text-align: start;

      span {
        text-decoration: underline;
      }
    }

    .circle_agree_focus {
      background-image: url('http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230322144855778.png');
      background-size: 110%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
    }
  }

  .sure_btn {
    // background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210203152926153.png");
    width: 70%;
    height: 34px;
    background-size: 100%;
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    border-radius: 34px;
    transform: translateX(-50%);
    background-color: #e0bd90;
  }

  .sure_words {
    font-size: 12px;
    max-width: 80%;
    padding-top: 5%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: #b9905c;
    margin-top: 5px;
  }
}
</style>